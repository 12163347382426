import React, { useRef, useState } from 'react';
import { ContextStats } from './fetchContextStats';
import AdClicksCard from './AdClicksCard';
import { SquareMeterCard } from './SquareMeterCard';
import AdsPublishedCard from './AdsPublishedCard';
import ChevronUp from './logos/chevronup';
import ChevronDown from './logos/chevrondown';
import { Badge } from '@warp-ds/react';
import DotNavigation from './dot_navigation';
import { NotificationsCard } from './NotificationsCard';
import ChevronLeft from './logos/chevronleft';
import ChevronRight from './logos/chevronright';
import Modal from './Modal';

interface Props {
    contextStats: ContextStats;
}

export type StatisticsType = '1' | '2' | '3';

const collapsedStorageKey = 'realestate-search-context-info-collapsed';
export const SearchStatsItem = ({ contextStats }: Props) => {
    const isMobile = window.innerWidth < 768;

    const storedCollpsed = localStorage.getItem(collapsedStorageKey);

    const [collapsed, setCollapsed] = useState(storedCollpsed !== null);
    const [index, setIRndex] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const isBTS = window.location.pathname.includes('/homes/');

    const redirectUrl = window.location.search
        ? `/realestate/boligmarkedet${window.location.search}`
        : '/realestate/boligmarkedet/';

    function doCollapse(collapse: boolean) {
        setCollapsed(collapse);
        if (collapse) {
            localStorage.setItem(collapsedStorageKey, 'yes');
        } else {
            localStorage.removeItem(collapsedStorageKey);
        }
    }

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollClearTimer = useRef<NodeJS.Timeout | undefined>();
    const handleScroll = () => {
        clearTimeout(scrollClearTimer.current);

        scrollClearTimer.current = setTimeout(() => {
            clearTimeout(scrollClearTimer.current);
            const newScrollLeft = scrollRef.current?.scrollLeft;
            if (newScrollLeft != undefined) {
                if (isMobile) {
                    const newIndex = Math.round(newScrollLeft / 193);

                    if (newIndex != index && newIndex >= 0 && newIndex <= 4) {
                        setIndex(newIndex);
                    }
                } else {
                    if (newScrollLeft > scrollLeft) {
                        setIndex(3);
                    } else {
                        setIndex(0);
                    }
                }
                setScrollLeft(newScrollLeft);
            }
        }, 250);
    };

    function scrollTo(index: number) {
        clearTimeout(scrollClearTimer.current);
        if (scrollRef.current) {
            const scrollWidth = isMobile ? 210 : 10000;
            const shift = index * scrollWidth;

            scrollRef.current.scrollTo({
                left: shift,
                behavior: 'smooth',
            });
        }
    }

    const setIndex = (index: number) => {
        setIRndex(index);
        scrollTo(index);
    };

    if (!isBTS && !(contextStats.env === 'local')) {
        return <div></div>;
    }

    return (
        <div
            style={{
                fontSize: '14pt',
                fontWeight: '300',
                lineHeight: '18px',
                padding: '16px 0px 16px 0px',
                maxWidth: '615px',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '8px',
                backgroundColor: '#F8FAFC',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: '16px',
                }}
            >
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '22px',
                    }}
                >
                    <span className="flex flex-row items-center gap-8">
                        {'Se statistikk for søket ditt '} <Badge variant="positive">Beta</Badge>
                    </span>
                </div>
                <div style={{ cursor: 'pointer', marginRight: '16px' }} onClick={() => doCollapse(!collapsed)}>
                    {collapsed && <ChevronDown />}
                    {!collapsed && <ChevronUp />}
                </div>
            </div>
            <div style={{ position: 'absolute' }}>
                <Modal show={showModal} onClose={() => setShowModal(false)} />
            </div>
            {!collapsed && (
                <div>
                    <div
                        style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                            paddingLeft: '16px',
                            fontSize: '14px',
                            fontWeight: '300',
                            lineHeight: '18px',
                        }}
                    >
                        {' '}
                        Tallene oppdateres basert på ditt søk
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div className="scrollableCards" ref={scrollRef} onScroll={handleScroll}>
                            <CardsToShow
                                contextStats={contextStats}
                                isMobile={isMobile}
                                redirectUrl={redirectUrl}
                                setShowModal={setShowModal}
                            />
                        </div>
                        {!isMobile && index > 0 && (
                            <div
                                className="naviFader"
                                style={{
                                    background:
                                        'linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
                                    left: 0,
                                }}
                            ></div>
                        )}
                        {!isMobile && index > 0 && (
                            <div className="naviCircle" style={{ left: '16px' }} onClick={() => setIndex(0)}>
                                <ChevronLeft />
                            </div>
                        )}
                        {!isMobile && index === 0 && (
                            <div
                                className="naviFader"
                                style={{
                                    background:
                                        'linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
                                    right: 0,
                                }}
                            ></div>
                        )}
                        {!isMobile && index === 0 && (
                            <div className="naviCircle" style={{ right: '16px' }} onClick={() => setIndex(3)}>
                                <ChevronRight />
                            </div>
                        )}
                    </div>

                    {isMobile && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '16px',
                            }}
                        >
                            <DotNavigation
                                index={index}
                                totalCount={isMobile ? 4 : 2}
                                navigateTo={setIndex}
                                showDots={isMobile}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '12px',
                            paddingTop: '14px',
                        }}
                    >
                        <a href={redirectUrl} target="_blank">
                            Se mer statistikk her
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

function CardsToShow({
    contextStats,
    isMobile,
    redirectUrl,
    setShowModal,
}: {
    contextStats: ContextStats;
    isMobile: boolean;
    redirectUrl: string;
    setShowModal: (show: boolean) => void;
}) {
    const linkRef = isMobile ? redirectUrl : '';

    function cardsToShow() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '12px',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                }}
            >
                <AdClicksCard contextStats={contextStats} isMobile={isMobile} redirectUrl={redirectUrl} />
                <SquareMeterCard
                    contextStats={contextStats}
                    isMobile={isMobile}
                    redirectUrl={redirectUrl}
                    setShowModal={setShowModal}
                />
                <NotificationsCard contextStats={contextStats} isMobile={isMobile} redirectUrl={redirectUrl} />
                <AdsPublishedCard contextStats={contextStats} isMobile={isMobile} redirectUrl={redirectUrl} />
                <div style={{ width: '4px', color: '#F8FAFC' }}>.</div>
            </div>
        );
    }

    if (isMobile) {
        return (
            <a style={{ textDecoration: 'inherit', color: 'inherit' }} href={linkRef} target="_blank">
                {cardsToShow()}
            </a>
        );
    } else {
        return <>{cardsToShow()}</>;
    }
}
